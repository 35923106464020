import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { InputTheme } from 'src/app/shared/utils';
import { FormText } from 'src/app/shared/models';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent {

  @Input() formItem: FormText;
  @Input() form: FormGroup;
  @Output() keyUp: EventEmitter<any> = new EventEmitter();
  @ViewChild('inputField') inputField;

  get theme(): InputTheme {
    return this.formItem.theme;
  }

  errorDescription = 'Field not valid';

  get isValid() { return this.form.controls[this.formItem.key].valid; }
  get isPristine() { return this.form.controls[this.formItem.key].pristine; }
  get isDisabled() { return this.form.controls[this.formItem.key].disabled; }
  get formItemErrors() { return this.form.controls[this.formItem.key].errors; }
  get showError() {
    let error = false;
    if (this.form.errors && this.form.errors.controlKey === this.formItem.key) {
      error = true;
      this.errorDescription = this.form.errors.description;
    } else if (this.formItemErrors) {
      error = true;
      this.errorDescription = this.formItemErrors.description;
    }
    if (error && !this.errorDescription) {
      this.errorDescription = 'Field not valid';
    }
    return !this.isPristine && (!this.isValid || error) && !this.isDisabled;
  }

  markAsDirty() {
    this.form.controls[this.formItem.key].markAsDirty();
  }
}
