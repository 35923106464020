export class FormItemBase<T> {

  value: T;
  key: string;
  label: string;
  placeholder: string;
  required: boolean;
  order: number;
  controlType: string;
  disabled: boolean;
  validator: any;
  info: any;
  options: {key: string, value: string}[] = [];
  theme: string;

  constructor(options: {
    value?: T,
    key?: string,
    label?: string,
    placeholder?: string,
    required?: boolean,
    order?: number,
    controlType?: string,
    disabled?: boolean,
    validator?: any,
    info?: string,
    theme?: string
  } = {}) {
    this.value = options.value;
    this.key = options.key || '';
    this.label = options.label || '';
    this.placeholder = options.placeholder || '';
    this.required = !!options.required;
    this.order = options.order === undefined ? 1 : options.order;
    this.disabled = !!options.disabled;
    this.controlType = options.controlType || '';
    this.validator = options.validator;
    this.info = options.info;
    this.theme = options.theme;
  }
}
