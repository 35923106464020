export class Tab {
    title: string;
    subtitle: string;
    relatedImage: string;
    label: string;

    constructor(init?: Partial<Tab>) {
        Object.assign(this, init);
    }
}
