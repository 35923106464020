export class Section {
    name: string;
    component;
    type: string;
    animated: boolean;
    fullWidth: boolean;

    constructor(init?: Partial<Section>) {
        Object.assign(this, init);
    }
}
