export type InputTheme = (
  'standard' |
  'rounded-white' |
  'dark-rectangular' |
  'big-dark-rectangular' |
  'dark-rectangular small' |
  'green-border'); // TODO think about names

export const InputThemes: {[key: string]: InputTheme} = {
  standard: 'standard',
  roundedWhite: 'rounded-white',
  darkRectangular: 'dark-rectangular',
  darkRectangularSmall: 'dark-rectangular small',
  greenBorder: 'green-border',
  bigDarkRectangular: 'big-dark-rectangular'
};
