import { Component, Input, OnInit, Output, EventEmitter, HostListener } from '@angular/core';
import { FormItemBase } from 'src/app/shared/models';
import { FormGroup } from '@angular/forms';
// import { fade } from 'app/shared/animations';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  // animations: [fade]
})
export class SelectComponent implements OnInit {
  @Input() formItem: FormItemBase<any>;
  @Input() form: FormGroup;

  @Output() selectionChanged = new EventEmitter<string>();

  errorDescription = 'Field not valid';

  get theme() {
    return this.formItem.theme;
  }

  // value;

  ngOnInit(): void {
    if (!!this.formItem) {
      this._initDefaultValue();
    }
  }

  isSelected(opt) {
    return this.formItem.value === opt.key;
  }

  optionChanged(key) {
    this.selectionChanged.emit(key);
    this._selectByKey(key);
  }

  optionClicked(opt) {
    this.selectionChanged.emit(opt.key);
    this._select(opt);
  }

  get isValid() { return this.form.controls[this.formItem.key].valid; }
  get isPristine() { return this.form.controls[this.formItem.key].pristine; }
  get isDisabled() { return this.form.controls[this.formItem.key].disabled; }
  get formItemErrors() { return this.form.controls[this.formItem.key].errors; }
  get showError() {
    let error = false;
    if (this.form.errors && this.form.errors.controlKey === this.formItem.key) {
      error = true;
      this.errorDescription = this.form.errors.description;
    } else if (this.formItemErrors) {
      error = true;
      this.errorDescription = this.formItemErrors.description;
    }
    if (error && !this.errorDescription) {
      if (this.formItemErrors.required) {
        this.errorDescription = 'Field is required!';
      } else {
        this.errorDescription = 'Field not valid';
      }
    }
    return !this.isPristine && (!this.isValid || error) && !this.isDisabled;
  }

  private _selectByKey(key) {
    this.formItem.value = key;
    this.form.controls[this.formItem.key].setValue(key);
  }

  private _select(opt) {
    this.formItem.value = opt.key;
    // this.value = opt.value;
    this.form.controls[this.formItem.key].setValue(opt.key);
  }

  private _initDefaultValue() {
    const defaultOptionCollection = this.formItem.options.filter(o => o.key === this.formItem.value);
    const defaultOption = defaultOptionCollection.length < 1 ? null : defaultOptionCollection[0];
    if (defaultOption) {
      this._select(defaultOption);
    }
  }
}
