/**
 * Created by rpetrusan on 19/12/2018.
 */

import { FormItemBase } from './form-item-base';

export class FormFileInput extends FormItemBase<File[] | File> {
  controlType = 'file-input';
  type: string;
  iconPath: string;
  showBrowseButton: boolean;
  multipleFiles: boolean;
  accept: string;

  constructor(options: {} = {}) {
    super(options);
    this.type = options['type'] || '';
    this.iconPath = options['iconPath'] || '';
    this.showBrowseButton = options['showBrowseButton'];
    this.multipleFiles = options['multipleFiles'];
    this.accept = options['accept'];
  }
}
