
import { makeSettings } from './environment.base';

export const settings = makeSettings({
  envName: 'dev',
  production: false,
  mailchimpApiKey: 'undefined',
  firebase: {
    apiKey: 'AIzaSyCP8vNeVCVmSsO0NNpRMiMTgufhhukByFA',
    authDomain: 'nocodeai.firebaseapp.com',
    databaseURL: 'https://nocodeai.firebaseio.com',
    projectId: 'nocodeai',
    storageBucket: 'nocodeai.appspot.com',
    messagingSenderId: '732021571693',
    appId: '1:732021571693:web:ed5bf852e49a986bc0bf64'
  }
});
