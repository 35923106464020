import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormItemBase } from 'src/app/shared/models';

@Component({
  selector: 'app-dynamic-form-item',
  templateUrl: './dynamic-form-item.component.html',
  styleUrls: ['./dynamic-form-item.component.scss']
})
export class DynamicFormItemComponent {

  @Input() formItem: FormItemBase<any>;
  @Input() form: FormGroup;
  @Input() theme: string;
  @Input() style: {
    height, background
  } = { height: 38.5, background: 'transparent' };

  @Input() set disabled(value) {
    if (value) {
      this.form.controls[this.formItem.key].disable();
    } else {
      this.form.controls[this.formItem.key].enable();
    }
  }

  get isValid() { return this.form.controls[this.formItem.key].valid; }
}
