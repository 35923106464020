export class Feature {
    title: string;
    text: string;
    icon: string;
    bigTitle: boolean;
    relatedImage: string;

    constructor(init?: Partial<Feature>) {
        Object.assign(this, init);
    }
}
