import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';


@Injectable()
export class FirebaseService {

    constructor(
        private firestore: AngularFirestore
    ) { }

    addQuizAnswers(data) {
        this.firestore.collection('quiz').add(data);
    }
}
