import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  FeatureComponent,
  FooterComponent,
  DynamicFormItemComponent,
  InputComponent,
  SelectComponent
} from './components';

@NgModule({
  declarations: [
    FeatureComponent,
    FooterComponent,
    DynamicFormItemComponent,
    InputComponent,
    SelectComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    // Modules
    MaterialModule,

    // Components
    FeatureComponent,
    FooterComponent,
    DynamicFormItemComponent,
    InputComponent,
    SelectComponent
  ]
})
export class SharedModule { }
