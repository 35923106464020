import { Component, OnInit, Input } from '@angular/core';
import { Feature } from '../../models/feature';

@Component({
  selector: 'app-feature',
  templateUrl: './feature.component.html',
  styleUrls: ['./feature.component.scss']
})
export class FeatureComponent implements OnInit {

  @Input() feature: Feature;
  @Input() isSelected = false;
  @Input() selectable = false;

  constructor() { }

  ngOnInit() {
  }

}
