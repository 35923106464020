/**
 * Created by benicosma on 22/06/2017. (security-system)
 */

import { FormItemBase } from './form-item-base';
import { InputThemes, InputTheme } from '../utils/theme';

export class FormText extends FormItemBase<string> {
  controlType = 'textbox';
  type: string;
  iconPath: string;
  theme: InputTheme;

  constructor(options: {} = {}) {
    super(options);
    this.type = options['type'] || '';
    this.iconPath = options['iconPath'] || '';
    this.theme = options['theme'] || InputThemes.standard;
  }
}
