export interface AppSettings {
  envName: string;
  production: boolean;
  mailchimpApiKey: string;
  firebase: any;
}

export const defaultSettings: AppSettings = {
  envName: '',
  production: false,
  mailchimpApiKey: '',
  firebase: {}
};

export function makeSettings(settings): AppSettings {
  return Object.assign({}, defaultSettings, settings);
}
