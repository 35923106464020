import {FormItemBase} from './form-item-base';

export class FormSelect extends FormItemBase<string> {
  controlType = 'select';

  constructor(options: {} = {}) {
    super(options);
    this.options = options['options'] || [];
  }
}
