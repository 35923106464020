import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormItemBase } from 'src/app/shared/models';


@Injectable()
export class FormControlService {

    static addValidator(formGroup: FormGroup, controlName: string, validators: any[]) {
        formGroup.controls[controlName].setValidators(validators);
    }

    constructor() { }

    toFormGroup(items: FormItemBase<any>[]) {
        const group: any = {};

        items.forEach(item => {
            group[item.key] = item.required ? new FormControl({ value: item.value || '', disabled: item.disabled },
                Validators.compose([Validators.required, item.validator]))
                : new FormControl({ value: item.value || '', disabled: item.disabled },
                    Validators.compose([item.validator]));
        });

        return new FormGroup(group);
    }

    /**
     * Marks all controls in a form group as touched
     * @param formGroup - The group
     */
    markFormGroupAsDirty(formGroup: FormGroup) {
        (Object as any).values(formGroup.controls).forEach(control => {
            control.markAsDirty();

            if (control.controls && control.controls.length > 0) {
                control.controls.forEach(c => this.markFormGroupAsDirty(c));
            }
        });
    }
}
