import { FormItemBase } from './form-item-base';

export class FormRadioQuiz extends FormItemBase<string> {
    controlType = 'radio-quiz';

    constructor(options: {} = {}) {
        super(options);
        this.options = options['options'] || [];
    }
}
